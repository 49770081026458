/* eslint-disable vtex/prefer-early-return */
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect, useMemo, useState } from 'react'
import { parseSearchState, SearchProvider } from '@faststore/sdk'
import type { SearchState } from '@faststore/sdk'
import axios from 'axios'
import type {
  CmsBlock,
  LandingPageQueryQuery,
  LandingPageQueryQueryVariables,
} from '@generated/graphql'
import { useSession } from 'src/sdk/session'
import { applySearchState } from 'src/sdk/search/state'
import RenderCMS from 'src/components/RenderCMS'
import ProductGallery from 'src/components/sections/ProductGallery'
import Newsletter from 'src/components/sections/Newsletter'
import StockAvailable from 'src/components/common/StockAvailable/StockAvailable'
import { ITEMS_PER_PAGE } from 'src/constants'
import 'src/styles/pages/lp.scss'

type Props = PageProps<
  LandingPageQueryQuery,
  LandingPageQueryQueryVariables,
  unknown
> & { slug: string }

function Page(props: Props) {
  const { locale } = useSession()
  const [preview, setPreview] = useState<PreviewData>(null)

  const getCmsData = async (
    contentType: string,
    documentId: string,
    versionId: string
  ) => {
    await axios
      .post('/api/getCmsContent', {
        contentType,
        documentId,
        versionId,
      })
      .then((response) => {
        setPreview(response?.data)
      })
      .catch((error) => {
        console.error('Error -> ', error)
      })
  }

  useEffect(() => {
    const SearchParams = new URLSearchParams(window?.location?.search)
    const contentType = SearchParams.get('contentType')
    const documentId = SearchParams.get('documentId')
    const versionId = SearchParams.get('versionId')

    if (contentType && documentId && versionId) {
      getCmsData(contentType, documentId, versionId)
    }
  }, [])

  const {
    data: { site, allCmsInstitutionalPage },
    location: { href, pathname },
  } = props

  const { edges } = allCmsInstitutionalPage

  const [cmsData] = edges
    ?.filter((item) => item?.node?.config?.pageConfig?.slug === props.path)
    ?.map((e) => e?.node)

  const cmsPageData = preview ?? cmsData

  const description = site?.siteMetadata?.description ?? ''
  const title = site?.siteMetadata?.title ?? ''

  const pageConfig = cmsData?.config?.pageConfig

  const selectedFacets = [
    {
      key: 'productClusterIds',
      value: pageConfig?.hasProductGallery
        ? String(pageConfig?.collectionID)
        : '',
    },
  ]

  const useSearchParams = (facets?: any): SearchState => {
    const selectedSearchFacets = facets

    return useMemo(() => {
      const maybeState = href ? parseSearchState(new URL(href)) : null

      return {
        page: maybeState?.page ?? 0,
        base: maybeState?.base ?? pathname,
        selectedFacets:
          maybeState && maybeState.selectedFacets.length > 0
            ? maybeState.selectedFacets
            : selectedSearchFacets ?? [],
        term: maybeState?.term ?? null,
        sort: maybeState?.sort ?? 'score_desc',
      }
    }, [selectedSearchFacets])
  }

  const searchParams = useSearchParams(selectedFacets)

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* SEO */}
      <GatsbySeo
        title={title}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        description={description}
        canonical={props.location.pathname}
        language={locale}
        openGraph={{
          type: 'website',
          title,
          description,
        }}
      />
      <RenderCMS sections={cmsPageData?.sections} />
      {pageConfig?.hasProductGallery && (
        <div data-fs-category-results>
          <ProductGallery
            title={pageConfig?.collectionTitle ?? ''}
            useFilters={false}
          />
        </div>
      )}
      <div className="lp__newsletter">
        <StockAvailable icon />
        <Newsletter
          title="Recibí promociones y novedades"
          subtitle="Ingresá tu correo electrónico"
        />
      </div>
    </SearchProvider>
  )
}

/**
 * This query is run during SSG
 * */
export const querySSG = graphql`
  query LandingPageQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
    allCmsInstitutionalPage {
      edges {
        node {
          name
          sections {
            data
            name
          }
          seo {
            siteMetadataWithSlug {
              slug
            }
          }
          config {
            pageConfig {
              slug
              background
              hasProductGallery
              collectionID
              collectionTitle
            }
          }
        }
      }
    }
  }
`
type PreviewData = {
  name: string
  sections: Array<{
    data: CmsBlock['data']
    name: string
  }>
  seo: {
    siteMetadataWithSlug: {
      slug: string | null
      description: string | null
      title: string | null
      titleTemplate: string | null
    } | null
  } | null
  config: {
    pageConfig: {
      slug: string
    }
  } | null
} | null

export default Page
